const cacheSent = new Set()

export default defineI18nConfig(() => {
  return {
    legacy: false,
    locale: "en",
    fallbackLocale: "en",
    missing: async function (locale, key, vm) {
      const isClient = import.meta.client
      if (!isClient) {
        return `${key}`;
      }

      if (cacheSent.has(key)) {
        return `${key}`;
      }
      await rawTrpcRequest({
        locale,
        key,
        appScope: "crm"
      }, {
        method: 'POST',
        route: 'i18n',
        procedure: 'lost'
      })
      cacheSent.add(key)
      return `${key}`;
    },
  }
})
